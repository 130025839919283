<div class="flex items-center justify-center lang-switch jost">
	<div
		class="lang-switch-button flex justify-center items-center"
		[ngClass]="{ selected: translateService.currentLang === 'es' }"
		(click)="useLanguage('es')"
	>
		ES
	</div>
	<div
		class="lang-switch-button justify-center flex items-center"
		[ngClass]="{ selected: translateService.currentLang === 'en' }"
		(click)="useLanguage('en')"
	>
		EN
	</div>
</div>
